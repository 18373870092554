<template>
  <div class="container">
    <div class="divide"></div>
    <div class="tabs">
      <span @click="backHome()">首页 > </span>
      <span @click="routerProductList()">产品中心 > </span>
      <span>{{ productDetail.productName }}</span>
    </div>
    <!-- <el-button @click="backHome()">返回列表</el-button> -->
    <div class="content">
      <div class="left">
        <el-menu
          :default-active=" String(lineId)"
          class="el-menu-vertical-demo"
          @open="handleOpen"
          @close="handleClose"
        >
          <div class="productCenter">产品中心</div>
          <template v-for="item in menuList">
          <el-menu-item v-if="item.children && item.children.length === 0" :key="item.id" :index="item.id + ''"
           >
            <!-- <i class="el-icon-location"></i> -->
            <span slot="title">{{ item.name }}</span>
          </el-menu-item>
          <el-submenu v-else :key="item.id" :index="item.id + ''">
            <template slot="title">
              <span slot="title">{{ item.name }}</span>
            </template>
            <el-menu-item-group>
              <template v-for="child in item.children">
                <el-menu-item class="menu-item-group" :key="child.id+1" :index="child.id + ''"
                 @click="changeline(child.id)">
                 <!-- 左侧选中竖线 -->
                 <span :class="lineId==child.id ? 'line-active':'line'"></span>
                  <span>{{child.productName}}</span></el-menu-item>
              </template>
            </el-menu-item-group>
          </el-submenu>
        </template>
          
        </el-menu>
      </div>
      <div class="right">
        <div class="flex">
          <img style="max-height: 4rem;" :src="productDetail.productImage" alt="" />
          <div class="info">
            <div class="title">{{ productDetail.productName }}</div>
            <div class="block">
              <img
                style="width: 0.3rem; vertical-align: middle"
                src="../../assets/product/cpzt.png"
                alt=""
              />
              <span>产品型号：{{ productDetail.productAlias }}</span>
            </div>
            <div class="block">
              <img
                style="width: 0.3rem; vertical-align: middle"
                src="../../assets/product/cd.png"
                alt=""
              />
              <span>产地：杭州</span>
            </div>
            <div class="block">
              <img
                style="width: 0.3rem; vertical-align: middle"
                src="../../assets/product/sj.png"
                alt=""
              />
              <span>更新时间：{{productDetail.gmtModify }}</span>
            </div>
            <div class="btnGroup">
              <div class="box hotline">
                <img
                  style="width: 0.3rem; vertical-align: middle"
                  src="../../assets/product/kfrx.png"
                  alt=""
                />
                <span>客服热线：18758021092</span>
              </div>
              <div class="box offer" @click="getOffer()">
                <img
                  style="width: 0.25rem; vertical-align: middle"
                  src="../../assets/product/bj.png"
                  alt=""
                />
                <span>获取报价</span>
              </div>
            </div>
          </div>
        </div>
        <!-- 产品详情介绍 -->
        <div class="detailIntro">
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="功能说明" name="first" class="introduce indent">
             {{ productDetail.productDescription }}
            </el-tab-pane>
            <el-tab-pane label="型号说明" name="second" class="indent">
              产品型号：{{ productDetail.productAlias }}<br />
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// 在页面加载完成后执行
window.onload = function() {
  // 将滚动条置于顶部
  setTimeout(function() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, 100);
}
export default {
  data() {
    return {
      productDetail:'',
      // 标签页·切换
      activeName: "first",
      // 默认菜单栏id
      // 竖直线对应id
      lineId:'14',
      // 菜单栏
      menuList:[
        {
          name:'产品列表',
          id:1,
          children:[
            
          ]
        },
    ]
    };
  },
  methods: {
    // 请求产品详情
    requestDetail(id){
      let param ={
       id:id
      }
      this.$get("/web/product/load", param)
        .then((res) => {
          // 主要参数
          if (res.code == 1) {
            console.log(res.data);
            // this.menuList[0].children=this.menuList[0].children.concat(res.data)
            this.productDetail= res.data
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch(() => {
          console.log("异常");
        });
    },
    // 请求产品列表
    requestProductList() {
      var param = {
        pageNum: 1,
        pageSize:11,
      };
      this.$post("/web/product/pageList", param)
        .then((res) => {
          // 主要参数
          if (res.code == 1) {
            // console.log(res.data);
            this.menuList[0].children=this.menuList[0].children.concat(res.data.list)
            // console.log(this.menuList)
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch(() => {
          console.log("异常");
        });
    },
    // 获得报价
    getOffer(){
      this.$message.warning('详情请咨询客服');
    },
    // 标签页切换
    handleClick(tab, event) {
      console.log(tab, event);
    },
    // 跳转页面至产品中心
    routerProductList() {
      this.$router.push("/productList");
    },
    // 回到首页
    backHome() {
      this.$router.push("/index");
      this.$store.commit("changeTheme", true);
      this.$store.commit("changeIndex", 0);
    },
    // 变化line
    changeline(id){
      console.log(id)
      this.lineId = id;
      this.requestDetail(id)
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
  },
  created() {
    this.productDetail =JSON.parse(localStorage.getItem('productDetail'));
    
    // console.log(this.productDetail)
  },
  mounted(){
    // this.requestDetail(14)
    this.lineId = this.productDetail.id
    this.requestProductList()
  }
};
</script>
<style lang="scss">
.el-menu-item {
  background: #f5f5f5;
  color: #565656c4;
  font-size: 0.2rem;
  padding: 0 20px !important;
}

.el-menu-item-group__title {
 display: none !important;
}

.el-submenu__title {
  font-size: 0.23rem;
}

li{
  margin: 0;
}

/* tab标签 */
.detailIntro{
  .el-tabs__active-bar {
  width: 6rem !important;
}
.el-tabs__nav {
  background: #f5f5f5;
  text-align: center;
  height: 1rem;
  line-height: 1rem;
}
#tab-first,
#tab-second {
  width: 6rem !important;
  font-size: 0.3rem !important;
}
}

</style>
<style scoped lang="scss">
.container .divide {
  // height: 1.1rem;
  box-shadow: 0px 2px 6px 0px rgba(219, 219, 219, 0.5);
  margin: 0;
}

/* // 导航条 */
.tabs {
  width: 100%;
  height: 42px;
  background: #ffffff;
  border-top: 2px solid rgba(219, 219, 219, 0.5);
  box-shadow: 0px 2px 6px 0px rgba(219, 219, 219, 0.5);
  line-height: 42px;
  font-size: 0.2rem;
  z-index: 99;
  margin-top: 1.1rem;
  span:nth-child(1) {
    margin-left: 24px;
    color: #a3a3a3;
    cursor: pointer;
  }
  span:nth-child(2) {
    color: #a3a3a3;
    cursor: pointer;
  }
  span:nth-child(3) {
    color: #565656;
    cursor: pointer;
  }
}
.el-button {
  float: right;
  margin-top: 0.3rem;
  margin-right: 0.4rem;
}
.content {
  padding: 0.3rem 2rem;
  display: grid;
  grid-template-columns: 1fr 2fr; /* 两列，第一列占1份，第二列占2份 */
  // 左边菜单栏
  .left {
    width: 3.5rem;
    height: 100%;
    background: #f5f5f5;
    min-width: 200px;
    .productCenter {
      width: 3.5rem !important;
      height: 0.8rem;
      line-height: 0.8rem;
      background: #127bf2;
      font-size: 0.3rem;
      padding: 0 0.2rem;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
      min-width: 200px;
      box-sizing: border-box;
      cursor: pointer;
    }
    // 菜单栏
    .el-menu-vertical-demo {
      width: 3.5rem !important;
      border: none;
      background: #f5f5f5;
      min-width: 200px;
      padding-bottom: 20px;
      .line {
        display: inline-block;
        width: 3px !important;
        height: 50px;
        background: rgba(18, 123, 242, 0.2);
        margin-right: 0.2rem;
        margin-top: -5px;
      }
      .line-active {
        display: inline-block;
        width: 3px !important;
        height: 50px;
        background: #127BF2;
        margin-right: 0.2rem;
        margin-top: -5px;
      }
    }
  }
  // 图片以及产品介绍
  .right {
    width: 12rem;
    margin-left: 0.5rem;
    .title {
      font-size: 0.35rem;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #565656;
      cursor: pointer;
    }
    .indent{
      text-indent: 2em;
      margin: 0.2rem auto;
    }
    .flex {
      display: flex;
      img {
        width: 5rem;
        object-fit: contain;
      }
      .info {
        background-image: url("../../assets/product/dt.png");
        width: 7rem;
        background-size: (100%, 100%);
        background-repeat: no-repeat;
        padding: 0.3rem;
        margin-left: 0.2rem;
        .block {
          margin: 0.2rem 0;
          img {
            margin-right: 10px;
          }
          span {
            font-size: 0.25rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #565656;
            vertical-align: middle;
            cursor: pointer;
          }
        }
        .btnGroup {
          display: flex;
          .box {
            padding: 0.15rem;
            color: #fff;
            border-radius: 5px;
            font-size: 0.2rem;
            img {
              margin-right: 0.1rem;
            }
          }
          .hotline {
            background: linear-gradient(90deg, #ea5215 0%, #ffab02 100%);
            cursor: pointer;
            span{
              vertical-align: middle;
            }
          }
          .offer {
            background: #ea5310;
            margin-left: 0.5rem;
            cursor: pointer;
            span{
              vertical-align: middle;
            }
          }
        }
      }
    }
    .detailIntro {
      width: 12rem;
      margin: 0.5rem 0;
    }
  }
  // 功能和型号说明
  .introduce {
    font-size: 0.25rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #565656;
    line-height: 0.35rem;
    cursor: pointer;
  }
}
</style>
